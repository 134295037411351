.card {
    text-align: center;
    padding: 20px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.12);
    max-width: 1200px; /* specify the max-width for large screens */
    width: 90%; /* take 90% of small screens */
}

.my_container {
    display: flex;
    height: 18vw;
}

.name{
    display: flex;
    width: 65%;
    justify-content: center;
    align-items: center;
}

.title{
    font-size: 4vw; /* scalable font-size */
}

.subtitle{
    margin-top: 10px;
    font-size: 2vw; /* scalable font-size */
    color: darkslategray;
}

.images {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 50%;
    margin-left: -25%;
}

/* @media screen and (max-width: 768px) {
    .name, .images {
        width: 100%;
        margin-bottom: 20px;
    }
    .logo {
        margin-left: 0;
        width: 70%;
    }
    .my_container {
        flex-direction: column;
        height: auto;
    }
    .title{
        font-size: 6vw; 
    }
    .subtitle{
        font-size: 4vw; 
    }
} */
